import React from 'react';
import { Container, Divider, Grid, Typography, Box } from '@mui/material';
import BlogCard from './BlogCard';

const BlogList = ({ posts }) => {
  const gridContainerStyle = {
    maxHeight: '60vh',
    overflowY: 'auto',
  };

  const sortedPosts = posts
    .filter(post => post.visibility)
    .sort((a, b) => {
      if (a.isFeatured && !b.isFeatured) return -1;
      if (!a.isFeatured && b.isFeatured) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    
  return (
    <Container>
      <Typography variant='h5'>Blog</Typography>
      <Divider />
      <Box style={gridContainerStyle}>
        <Grid container spacing={4}>
          {sortedPosts.map((post) => (
            <Grid xl={12} lg={12} xs={12} md={12} item key={post.id}>
              <BlogCard post={post} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default BlogList;
