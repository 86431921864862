import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
const BlogCard = ({ post }) => {

  const { i18n } = useTranslation();
  const formattedDate = new Date(post.createdAt).toLocaleDateString();
  
  const isHungarian = i18n.language === 'hu';
  return (
    <Card sx={{ maxWidth: '100%', margin: 2 }}>
      {post.isFeatured && 
        <div style={{ background: "rgb(204,159,82)", padding: '5px' }}>
          <Typography variant="caption">Aktuálisan ajánlott bejegyzés</Typography>
        </div>
      }
      <CardMedia
        component="img"
        height="140"
        style={{ objectFit: "inherit"}}
        image={`https://api.eoa.hu/${post.image}`}
        alt={post.title}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box>
          <Typography gutterBottom variant="h5" component="div">
            {post.title}
          </Typography> 
          <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: isHungarian ? post.content : post.content }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: 3 }}>
          <Typography variant="caption" color="text.secondary">
            Keletkezett: {formattedDate}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BlogCard;
