
export const draftTexts = {
    navigation: {
        title: "Ékszer És Óra Szakmáért Országos Alapítvány"
    },
    firstCard: {
        title: "Alapítvány célja",
        bulletPoints: ["Az ékszer és óraszakma támogatása", "Feltételek megteremtése az értékek <br> közvetítéséhez", "Fenntartható képzési rendszer kialakítása"],
        description: "Az alapítvány elsődleges célja az, hogy felkarolja, összefogja és támogassa a magyar ékszer- és óraszakmát, ezen szakmák képviselőit, valamint ápolja ezen szakmák kulturális örökségét, hagyományait és szellemiségét. Az alapítvány a működése során meg kívánja teremteni azokat a feltételeket, melyek elősegítik a szellemi, a művészi, a környezeti, gazdasági értékek és hagyományok közvetítését és megismertetését a magyar társadalommal, illetve elősegítik a fenntartható társadalmi környezet kialakítását. Az alapítvány továbbá célul tűzi ki az iskolarendszeren kívüli ifjúsági- és felnőttoktatási képzések megszervezését, a szakmák képviselőinek versenyekre és nemzetközi szakmai kiállításokra való eljutásának támogatását, öregbítve ezzel Magyarország hírnevét és elismerését."
    },
    secondCard: {
        title: "Alapítvány tevékenysége",
        bulletPoints: ['Ékszer és óraszakma összefogása <br> forgalmának növelése',
            "Magyar gyártók támogatása <br> szakmai pályázatok kiírása",
            "Külföldi piacra lépés támogatása",
            "Kiállításokon való részvétel",
            "Ékszer és óraszakmához kapcsolódó szakmai előadások és rendezvények szervezése"],
        description: "Az alapítvány működése során az ékszer- és óraszakma megreformálását, összefogását, valamint ebben a körben gazdaságfejlesztési feladatok ellátását célozza meg belföldön és külföldön egyaránt. Az alapítvány céljainak megvalósítása érdekében",
        descriptionBulletPoints: ["elméleti és gyakorlati foglalkozásokat", "előadásokat szervez, valamint", "szakmai bemutatókat, kiállításokat, képzéseket, oktatásokat", "és más rendezvényeket tervez megvalósítani"
        ]
    },
    thirdCard: {
        title: "Alapítvány alapítója",
        subTitle: "Horváth László",
        description: "Gyerekkorom óta érdekelt az ékszer és a művészetek, divat, művészettörténelem világa. Egész eddigi életemet meghatározta ez az irányzat.Tizenéves koromtól kézműves és alkotóműhelyek, szakkörök, táborok lelkes tagja voltam és tanáraim támogatásával több országos pályázatot nyertem. Első kiállításom 1989 -ben volt 16 évesen. 2011 -ben volt szerencsém több elismert ékszer világkiállításon bemutatni alkotásaimat, ékszereimet. Hatalmas sikerrel és elismeréssel tértünk haza. 31 éve dolgozom az ékszerek világában, kisebb kitéréseket tettem más művészeti ágakba, de ékszerek nélkül nem telt el még napom. Ezalatt az idő alatt mindig ékszertervező voltam – emellett kezdetben kiskereskedő, majd nagykereskedő – , importőr lettem saját 59 fôs manufaktúrával. Jelenleg ékszer, drágakő, óra véleményvezér (influenszer)."
    },
    fourthCard: {
        title: "Alapítvány jellege",
        bulletPoints: ['Nyitott'],
        description: "Az alapítvány nyitott, ahhoz bármely hazai vagy külföldi természetes és jogi személy bármikor az Alapítvány fennállása alatt tetszése szerinti vagyoni hozzájárulással csatlakozhat Bankszámla szám: 10300002 - 13518522 - 00014909"
    },
    fifthCard: {
        title: "Ügyvezető testület",
        subTitle: "Kuratórium",
        members: [
            { name: "Kiss László", position: "Elnök" },
            { name: "Sebők Sándor", position: "Alelnök" },
            { name: "Mersitz Zoltán", position: "Alelnök" }
        ]
    },
    sixthCard: {
        title: "Szekciók",
        bulletPoints: ["Ötvös/gyártó", "Órás", "Junior",  "Drágakő és gyöngy", "Kereskedelem" , "Etikai"],
        description: "A szekciók célja és küldetése a szakmai bizottság munkájának támogatása és az egyes szakmai területek aktív képvislete annak érdekében, hogy a megfelelő hangsúlyú érdekek eljussanak a döntéshozókhoz. A szekció elnökök és tagjaik kiválasztása folyamatban van."
    },

    emailTextContent: {
        description: "Tájékoztatást kérek az alábbi e-mail címre pályázatokkal, szakmai rendezvényekkel, magyar gyártói támogatásokkal kapcsolatosan. Továbbá az alapítvány szakmát érintő és segítő minden tevékenységről.",
        caption: "Az TÁMOGATOM! gomb megnyomásával beleegyezik, hogy adatait belső statisztikákra használhatjuk fel. Csak az email cím kerül eltárolásra!"
    },
    emailComponentTexts: {
        buttonText: "Küldés",
        incorrectFormat: "Helytelen e-mail formátum",
        invalidEmailOrCaptcha: "Adjon meg érvényes e-mail címet, vagy töltse ki az ellenőrzést!",
        emailExistsError: "Az e-mail cím már szerepel az adatbázisunkban!",
        serverConnectionError: "Hiba a szerverhez való kapcsolódás során.",
        unsuccessfullEmailSending: "Sikertelen e-mail küldés, kérjük töltse be újra az oldalt, és próbálja újra!",
        thanksForSendingEmail: "Köszönjük a támogatást!"
    },

    tenders: {
        title: "Projektjeink",
        bulletPoints: [
            "Forgalomnövelő kiállítás és vásár",
            "Designer ékszer-óra-dísztárgy területen",
            "Tender kiírás 2024-ben"
        ],
        description: "Iratkozz fel a hírlevélre, hogy az elsők között értesülj!"
    },
    footer: "A weboldalon található információk, fotók és videók az oldal üzemeltetőjének kizárólagos írásbeli engedélyével használhátóak fel. Minden jog fenttarva "
}