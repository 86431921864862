import { useEffect, useState } from 'react';
import './App.css';
import { Divider, Typography } from '@mui/material';
import Header from './components/navigation/NavigationBar';
import { MEDIA } from './assets/images/images';
import { draftTexts } from './i18n/hu-HU';
import SeparateBoxesComponent from './components/TwoCardComponent'
import PictureAndCardComponent from './components/PictureAndCardComponent';
import MembersContainer from './components/MemberContainer';
import EmailFormWithRecaptcha from './components/EmailComponent';
import { useMediaQuery } from '@mui/material';
import { FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import SplashScreen from './components/SplashComponent/SplashComponent'
import { splashShowTime } from './utils/utils';
import { MdOutlineMarkEmailUnread } from 'react-icons/md';
import { FaLinkedin, FaFacebook, FaTiktok } from 'react-icons/fa';
import BlogList from './components/BlogList';

const mockBlogList = [
  {
    id: 2,
    title: "Rendezz az ékszer üzletedben designer ékszer, óra bemutatót. ",
    content: "<b>Ékszer és óraszakmáért Országos Alapítvány meghirdeti a csatlakozási lehetőséget  a Design 360 kiállítás side eventjeire, ötvösök, ékszer designerek, órások részére!</b>  <span> Ha szeretnél több vásárlót, akkor a Design 360 kiállítás keretében lehetőség nyílik 2024. október 15. és 20. között arra, hogy rácsatlakozz erre ékszerüzleteddel, üzleteddel. A kiemelt kommunikációnak köszönhetően magyar és külföldi érdeklődők célzottan érkezhetnek az üzletedbe. Az üzletben lehet akár:</span>  <ul>     <li> Designer ékszert, órát, dísztárgyat bemutatni (meghívott művész, aki ismertetheti történetét, alkotói inspirációit, műveit), vagy </li>      <li> Vásárlói bemutató- rendezvényt  </li>      <li> WorkShop </li>      <li> Akciók, Vip vásárlás, Jótékonysági rendezvény, Kerekasztal-beszélgetés </li>  </ul>  <span> Éljetek a lehetőséggel, hogy felkerülhessenek a kommunikációs event listára.   A vásárlói rendezvény színvonalát és egyediségét borkostoló szervezésével is tudjuk támogatni.   Az alapítvány elnöksége várja jelentkezésed az info@eoa.hu email címre 2024.07.25-ig, program tematika megjelölésével. </span>",
    authorId: "Test",
    status: "test",
    isFeatured: true,
    visibility: true,
    language: "hun",
    image: "/images/ae361867-fc40-4273-93d5-6165e5f17799.png",
    createdAt: "2024-07-08T18:17:28.728Z",
    modifiedAt: "2024-07-11T18:17:28.728Z"
  },
  {
    id: 2,
    title: "Rendezz az ékszer üzletedben designer ékszer, óra bemutatót. ",
    content: "<b>Ékszer és óraszakmáért Országos Alapítvány meghirdeti a csatlakozási lehetőséget  a Design 360 kiállítás side eventjeire, ötvösök, ékszer designerek, órások részére!</b>  <span> Ha szeretnél több vásárlót, akkor a Design 360 kiállítás keretében lehetőség nyílik 2024. október 15. és 20. között arra, hogy rácsatlakozz erre ékszerüzleteddel, üzleteddel. A kiemelt kommunikációnak köszönhetően magyar és külföldi érdeklődők célzottan érkezhetnek az üzletedbe. Az üzletben lehet akár:</span>  <ul>     <li> Designer ékszert, órát, dísztárgyat bemutatni (meghívott művész, aki ismertetheti történetét, alkotói inspirációit, műveit), vagy </li>      <li> Vásárlói bemutató- rendezvényt  </li>      <li> WorkShop </li>      <li> Akciók, Vip vásárlás, Jótékonysági rendezvény, Kerekasztal-beszélgetés </li>  </ul>  <span> Éljetek a lehetőséggel, hogy felkerülhessenek a kommunikációs event listára.   A vásárlói rendezvény színvonalát és egyediségét borkostoló szervezésével is tudjuk támogatni.   Az alapítvány elnöksége várja jelentkezésed az info@eoa.hu email címre 2024.07.25-ig, program tematika megjelölésével. </span>",
    authorId: "Test",
    status: "test",
    isFeatured: false,
    visibility: true,
    language: "hun",
    image: "/images/ae361867-fc40-4273-93d5-6165e5f17799.png",
    createdAt: "2024-07-11T18:17:28.728Z",
    modifiedAt: "2024-07-11T18:17:28.728Z"
  },
  {
    id: 2,
    title: "Rendezz az ékszer üzletedben designer ékszer, óra bemutatót. ",
    content: "<b>Ékszer és óraszakmáért Országos Alapítvány meghirdeti a csatlakozási lehetőséget  a Design 360 kiállítás side eventjeire, ötvösök, ékszer designerek, órások részére!</b>  <span> Ha szeretnél több vásárlót, akkor a Design 360 kiállítás keretében lehetőség nyílik 2024. október 15. és 20. között arra, hogy rácsatlakozz erre ékszerüzleteddel, üzleteddel. A kiemelt kommunikációnak köszönhetően magyar és külföldi érdeklődők célzottan érkezhetnek az üzletedbe. Az üzletben lehet akár:</span>  <ul>     <li> Designer ékszert, órát, dísztárgyat bemutatni (meghívott művész, aki ismertetheti történetét, alkotói inspirációit, műveit), vagy </li>      <li> Vásárlói bemutató- rendezvényt  </li>      <li> WorkShop </li>      <li> Akciók, Vip vásárlás, Jótékonysági rendezvény, Kerekasztal-beszélgetés </li>  </ul>  <span> Éljetek a lehetőséggel, hogy felkerülhessenek a kommunikációs event listára.   A vásárlói rendezvény színvonalát és egyediségét borkostoló szervezésével is tudjuk támogatni.   Az alapítvány elnöksége várja jelentkezésed az info@eoa.hu email címre 2024.07.25-ig, program tematika megjelölésével. </span>",
    authorId: "Test",
    status: "test",
    isFeatured: false,
    visibility: true,
    language: "hun",
    image: "/images/ae361867-fc40-4273-93d5-6165e5f17799.png",
    createdAt: "2024-07-11T18:17:28.728Z",
    modifiedAt: "2024-07-11T18:17:28.728Z"
  },
  {
    id: 2,
    title: "Rendezz az ékszer üzletedben designer ékszer, óra bemutatót. ",
    content: "<b>Ékszer és óraszakmáért Országos Alapítvány meghirdeti a csatlakozási lehetőséget  a Design 360 kiállítás side eventjeire, ötvösök, ékszer designerek, órások részére!</b>  <span> Ha szeretnél több vásárlót, akkor a Design 360 kiállítás keretében lehetőség nyílik 2024. október 15. és 20. között arra, hogy rácsatlakozz erre ékszerüzleteddel, üzleteddel. A kiemelt kommunikációnak köszönhetően magyar és külföldi érdeklődők célzottan érkezhetnek az üzletedbe. Az üzletben lehet akár:</span>  <ul>     <li> Designer ékszert, órát, dísztárgyat bemutatni (meghívott művész, aki ismertetheti történetét, alkotói inspirációit, műveit), vagy </li>      <li> Vásárlói bemutató- rendezvényt  </li>      <li> WorkShop </li>      <li> Akciók, Vip vásárlás, Jótékonysági rendezvény, Kerekasztal-beszélgetés </li>  </ul>  <span> Éljetek a lehetőséggel, hogy felkerülhessenek a kommunikációs event listára.   A vásárlói rendezvény színvonalát és egyediségét borkostoló szervezésével is tudjuk támogatni.   Az alapítvány elnöksége várja jelentkezésed az info@eoa.hu email címre 2024.07.25-ig, program tematika megjelölésével. </span>",
    authorId: "Test",
    status: "test",
    isFeatured: false,
    visibility: true,
    language: "hun",
    image: "/images/ae361867-fc40-4273-93d5-6165e5f17799.png",
    createdAt: "2024-07-11T18:17:28.728Z",
    modifiedAt: "2024-07-11T18:17:28.728Z"
  },
];

function App() {
  const isMdOrLower = useMediaQuery('(max-width: 960px)');
  const { t, i18n } = useTranslation();
  const [firstLoad, setFirstLoad] = useState(true);

  // Needed bcause in case of language switch english text might be longer or shorter, and the resize wont happen correctly
  const [prevLanguage, setPrevLanguage] = useState(i18n.language);

  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);


  const changeLanguage = (lng) => {
    console.log(i18n.language)
    i18n.changeLanguage(lng);
    setPrevLanguage(lng);

  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFirstLoad(false);
    }, splashShowTime);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch('https://api.eoa.hu/api/blog-posts');
        const data = await response.json();
        setBlogPosts(data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <div className="App" style={{ backgroundImage: `url(${MEDIA.backgroundPic})` }}>
      {(firstLoad && isMdOrLower) && <SplashScreen />} {/* Conditionally render SplashScreen */}
      <Header changeLanguage={changeLanguage} />
      <div style={{ paddingTop: isMdOrLower ? 100 : 140, margin: "0 5%" }}>
        {loading ? (
          <Typography variant="h6">Loading...</Typography>
        ) : (
          <BlogList posts={blogPosts} />
        )}
        <SeparateBoxesComponent showExpandButtonOnDesktop={true} title={`${t('firstCard.title')}`} bulletPoints={`${t('firstCard.bulletPoints', { returnObjects: true })}`} description={`${t('firstCard.description')}`} languageSwitched={prevLanguage} />
        <SeparateBoxesComponent showExpandButtonOnDesktop={true} isDesktopDefaultExpanded={true} title={`${t('secondCard.title')}`} bulletPoints={`${t('secondCard.bulletPoints', { returnObjects: true })}`} description={`${t('secondCard.description')}`} languageSwitched={prevLanguage} />
        <SeparateBoxesComponent showExpandButtonOnDesktop={true} title={`${t('tenders.title')}`} bulletPoints={`${t('tenders.bulletPoints', { returnObjects: true })}`} description={`${t('tenders.description')}`} languageSwitched={prevLanguage} />
        <PictureAndCardComponent showExpandButtonOnDesktop={true} isDesktopDefaultExpanded={true} title={`${t('thirdCard.title')}`} backgroundImageSrc={MEDIA.charterMember} description={`${t('thirdCard.description')}`} subTitle={`${t('thirdCard.subTitle')}`} />
        <SeparateBoxesComponent title={`${t('fourthCard.title')}`} bulletPoints={`${t('fourthCard.bulletPoints', { returnObjects: true })}`} description={`${t('fourthCard.description')}`} showListCss={false} />
        <MembersContainer title={`${t('fifthCard.title')}`} subTitle={`${t('fifthCard.subTitle')}`} members={draftTexts.fifthCard.members.map((member, index) => ({
          name: t(`fifthCard.members.${index}.name`),
          position: t(`fifthCard.members.${index}.position`)
        }))}
          languageSwitched={prevLanguage} />
        <SeparateBoxesComponent title={`${t('sixthCard.title')}`} bulletPoints={`${t('sixthCard.bulletPoints', { returnObjects: true })}`} description={`${t('sixthCard.description')}`} languageSwitched={prevLanguage} />
        <EmailFormWithRecaptcha />
      </div>
      <div style={{ background: 'rgb(182,182,182)', color: 'white', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <div>
          <a target="_blank" href="https://www.tiktok.com/@eoa.hu?_t=8nt6tDhdSjp&_r=1">
            <FaTiktok size="2em" />
          </a>
          <a target="_blank" href="https://www.instagram.com/ekszer.ora.alapitvany/">
            <FaInstagram size="2em" />
          </a>
          <a target="_blank" href="https://www.facebook.com/ekszer.ora.alapitvany?mibextid=LQQJ4d&rdid=8NDoLxIJHmoseyb6&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FoKpn44vENu3uRRW7%2F%3Fmibextid%3DLQQJ4d">
            <FaFacebook size="2em" />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/%C3%A9kszer-%C3%A9s-%C3%B3raszakm%C3%A1%C3%A9rt-orsz%C3%A1gos-alap%C3%ADtv%C3%A1ny/">
            <FaLinkedin size="2em" />
          </a>
          <a target="_blank" href="mailto:info@eoa.hu">
            <MdOutlineMarkEmailUnread size="2em" />
          </a>
        </div>
        <Typography variant="subtitle2" style={{ fontFamily: "BrandingSemilight", fontWeight: "bold" }}> {t('footer')} © {new Date().getFullYear()}</Typography>


      </div>
    </div>
  );
}

export default App;
